<template>
    <div class="page_content">
        <div class="page_edit_content">
            <Form
                ref="form"
                @handleSubmit="handleSubmit"
                :form_data="form_data"
            >
            
                <div slot="photo_setting">
                    <a @click="add_photo()">
                        <a-icon type="plus-circle" />
                        <span style="padding-left: 5px">添加拍照设置</span>
                    </a>
                    <!-- <a-input type="hidden" name="week_data" v-decorator="['week_data', week_old]" /> -->
                    <div>
                        <ul>
                            <li v-for="(item, index) in photo_data" :key="index">
                                <div>
                                    <span class="span_style">开始月份：{{ item.start_month}}</span>
                                    <span class="span_style">结束月份：{{ item.end_month}}</span>
                                    <span class="span_style">门店设施：{{ item.names}}</span>
                                    <a @click="del_photo(index)">删除</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div> 
            </Form>
        </div>
        <EditPop @handleSubmit="photo_submit" :form_data="form_data_photo" :visible.sync="photot_visible"></EditPop>
    </div>
</template>

<script>
import EditPop from "@/components/EditPop";
import Form from "@/components/Form";
import { getConfig, saveConfig } from '@/api/cost'

    const setting_data = [
                        {
                            key:"signboard",
                            value:"店招"
                        },
                        {
                            key:"shelves",
                            value:"货架陈列"
                        },
                        {
                            key:"materials",
                            value:"广宣物料"
                        },
                        {
                            key:"freezing",
                            value:"冰冻化"
                        },
                        {
                            key:"box",
                            value:"堆箱"
                        },
            ];

    const month_data = [
        {
            key:1,
            value:"一月",
        },
        {
            key:2,
            value:"二月",
        },
        {
            key:3,
            value:"三月",
        },
        {
            key:4,
            value:"四月",
        },
        {
            key:5,
            value:"五月",
        },
        {
            key:6,
            value:"六月",
        },
        {
            key:7,
            value:"七月",
        },
        {
            key:8,
            value:"八月",
        },
        {
            key:9,
            value:"九月",
        },
        {
            key:10,
            value:"十月",
        },
        {
            key:11,
            value:"十一月",
        },
        {
            key:12,
            value:"十二月",
        },
    ]
export default {
    components: {
        Form,
        EditPop,
    },
    data() {
        return {
            visible: false,
            photot_visible:false,
            info: {
                applets: {

                }
            },
            photo_data:[],
            form_data: {
                show_submit_btn: true,
                show_close_btn: false,
                close_reset_btn: true,
                list: [  
                    
                ]
            },
            form_data_photo: {
                title:"拍照设置",
                list:[
                    {
                        type: "select",
                        name: "start_time",
                        title: "开始时间",
						options: {
                            rules: [{
                                required: true,
                                message: "请选择开始时间"
                            }]
                        },
                        list:month_data
                    },
                    {
                        type: "select",
                        name: "end_time",
                        title: "结束时间",
						options: {
                            rules: [{
                                required: true,
                                message: "请选择结束时间"
                            }]
                        },
                        list:month_data
                    },
                    {
                        type: "select",
                        name: "freezing_setting",
                        title: "门店设施",
                        mode:"multiple",
						options: {
                            rules: [{
                                required: true,
                                message: "请选择门店设施"
                            }]
                        },
                        list: setting_data
                    }
                ]
            }
        };
    },
    async created() {
        getConfig().then(res => {
            this.form_data.list = res.data.list.map(item => {

                if (item.type == 'checkbox') {
                    item.options = {
                        initialValue: item.value.split(','),
                    }
                } else if(item.type == 'slot') {
                    if (item.name == 'photo_setting') {
                        this.photo_data = item.value
                    }
                } else{
					item.options = {
                        initialValue: item.value,
                    }
				}

                return item
            });
        })
    },
    methods: {
        get_form_list() {

        },
        handleSubmit({ values }) {
            let list = [];
            this.form_data.list.forEach(item => {
                if (item.type == 'checkbox') {
                    list.push({
                        id: item.id,
                        value: values[item.name].join(','),
                        name: item.name
                    })
                } else if (item.type == 'slot') {
                    if (item.name == 'photo_setting') {
                        list.push({
                            id: item.id,
                            value: JSON.stringify(this.photo_data),
                            name: item.name
                        })
                    }
                } else {
                    list.push({
                        id: item.id,
                        value: values[item.name],
                        name: item.name
                    })
                }
            })

            saveConfig({
                data: {
                    value: JSON.stringify(list)
                },
                info: true,
            }).then(res => {
                
            })
        },
        add_photo() {
            this.photot_visible = true
        },
        photo_submit(e) {
            let obj = {};
            let value = e.values
            let start = value.start_time
            let end = value.end_time
            let names = [];
            for (let i=0;i<value.freezing_setting.length;i++) {
                setting_data.forEach(it=>{
                    if (it.key == value.freezing_setting[i]) {
                        names.push(it.value)
                    }
                })
            }

            obj = {"start_month":start,"end_month":end,"names":names.toString(),"keys":value.freezing_setting.toString()}
            this.photo_data.push(obj)

            this.photot_visible = false
        },
        del_photo(key) {
            this.photo_data.splice(key,1)
        }
    }
};
</script>
<style lang="less" scoped>
.span_style{
    padding-right: 15px;
}
</style>
